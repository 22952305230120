<script lang="ts" setup>
import type { BlokMedia } from '~~/types/storyblok/bloks'
const props = defineProps<{ blok: BlokMedia | undefined }>()

const media = computed(() => {
  return props.blok?.media || []
})
</script>

<template lang="pug">
div(v-editable="blok" class="blok-media")
  StoryblokComponent(
    v-for="asset in media"
    :key="asset._uid"
    :blok="{ ...asset }"
  )
</template>

<style lang="pcss">
.blok-media {
  .media-item {
    & + .media-item {
      @apply mt-18;
    }
  }
}
</style>
